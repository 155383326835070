import React from 'react'
import { withTranslation } from 'react-i18next';
import {Footer, SignupDivider, 
//HeroPlain, Location
} from '../../components';
import {WebLayout} from '../../layouts';
const AboutPage = ({t}) => (
    <WebLayout alwaysSticky={true}
        link={[{
              rel: "alternate", href: "https://www.duuers.com/fi/about", hreflang: "fi"
          },{
              rel: 'alternate', href: 'https://www.duuers.com/en/about', hreflang: 'en'
          },{
              rel: 'alternate', href: 'https://www.duuers.com/en/about', hreflang: 'x-default'
          }]}
          title="About Duuers - Who we are and what we stand for"
          meta={[
            {name: 'description', content: 'Find out more about who we are, and what makes us tick. Look up our address or check out our latest job openings.'},
            {name: 'keywords', content: ''},
          ]}
        >
     
     <section className="content_4 bg_white padding_bottom25">
      <div className="container nopadding padding_left20 width_full text-left">
    {/*   <h2 className="font_second light font42 top140 padding_bottom20 ">Duuers.com solution is now part of the Louhi Networks.</h2> */}
      	<h2 className="font_second light font42 top140 padding_bottom20 ">{t('component.aboutus.maintitle')}</h2>
    {/*    <div className="width_full margin_auto top35 font20 light text">
          Louhi Networks has acquired the rights to Duuers.com proposal management tool and will develop the solution onwards together with its customers.
          <br></br>
          <br></br><div className="col-lg-7 float_right">
      		<img srcSet="" src="/images/hero_hands.jpg" className="padding_top20 padding_bottom20 width_full" alt="" />	
        </div>
          Louhi Networks supports its customers success with solutions that enable digital presence, sales and collaboration on the web. Louhi serves over 20.000 from small companies to large corporations and our turnover in 2019 was 2,3 mEur. We currently have 11 experts serving our customers. Find out more on our services and the company at <a href="https://www.louhi.fi/" target="_blank" rel="noopener noreferrer" className="link blue">www.louhi.fi</a>.
          <br></br><br></br>
          <br></br>
          <br></br>
        </div> */}
     {/*  <div className="width_full margin_auto top35 font20 light text">
            Before the bids were written on the paper and placed in the post office. Then moved to use word processing programs. Tenders were still placed in the mail - albeit the email. The course of action has retained the same although the pen changed to the writing machine and then on the computer.
        </div>  */}
         <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text1')}
         </div>
          <div className="col-lg-7 float_right">
      		<img srcSet="" src="/images/hero_hands.jpg" className="padding_top20 padding_bottom20 width_full" alt="" />	
          </div>
        
         <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text2')}
         </div>
         <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text3')}
         </div>
         <div className="width_full margin_auto top35 bottom30 font20 light text">
             {t('component.aboutus.text4')}
         </div> 

    {/*   <div className="width_full margin_auto top35 font20 light text">
            Duuers was born of the idea that bids could create a genuinely digitism. It means a digital library of bids and tenderer parts from which the creation of bids is easy than the use of Lego-fragments - intuitive and fast. In Duuers, the offer is not tied to a paper-stricken presentation, but it is easy to make the appearance of pleasing, clear and thus selling. The quickly done professional-looking tenders language for the quality of services and better lead to trade.
        </div>

        <div className="width_full margin_auto top35 font20 light text">
            The client does not have to fight with attachments and versions, but he gets a link to a bid on a network where it can be discussed on a closed client and the seller on the discussion board by tender. When a common tune can be found, you can accept the offer electronically and save their own copy of it to its own machine. Digital and all parties transparent!
        </div>

        <div className="width_full margin_auto top35 font20 light text">
            The Mission of Louhi Networks is to provide its customers with the company's digital identity and job solutions to make business more efficiently and facilitates. That is why Duuersk is part of our service provision. We will help all companies from the freenlanceres to the Ibsions to the entities to appear on their own symbol online and to cooperate with their feathers digitally and develop their business. For more than 22,000 customers, we will maintain more than 50,000 domains and we are a Google partner through which more than 1,000 customers have access to Google Workspace solutions for internal and external cooperation.
        </div>  */}

      </div>
    </section>
     
       {/* <section className="content_4 bg_white padding_bottom25">

            <div className="container nopadding max_width970 text-center">

      <br></br>

      <br></br>

      <br></br>

      <br></br>


				<h2 className="top50 font_second light font42">Duuers.com solution is now part of the Louhi Networks.</h2>

                <div className="max_width770 margin_auto top45 font22 light text">

					Louhi Networks has acquired the rights to Duuers.com proposal management tool and will develop the solution onwards together with its customers..

                    <br></br>

                    <br></br>

					Louhi Networks supports its customers success with solutions that enable digital presence, sales and collaboration on the web. Louhi serves over 20.000 from small companies to large corporations and our turnover in 2019 was 2,3 mEur. We currently have 11 experts serving our customers. Find out more on our services and the company at <a href="https://www.louhi.fi/" target="_blank" rel="noopener noreferrer">www.louhi.fi. </a>  

                </div>
            </div>

        </section>*/}
        <SignupDivider/>

        <Footer/>

    </WebLayout>

)

export default withTranslation()(AboutPage)